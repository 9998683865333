<template>
  <div>
    <div>
      <!-- <el-form layout="inline" :model="queryParam" :inline="true">
        <el-row :gutter="24">
          <el-col :md="6" :sm="8">
            <el-form-item label="输入服务">
              <el-select
                v-model="queryParam.code"
                placeholder="请选择"
                @change="getCode"
              >
                <el-option
                  v-for="item in xunList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form> -->
      <h2 style="color: red">
        温馨提示:因为浏览器安全限制,请先复制一下账号密码,点击查看会需要输入
      </h2>
      <el-table :data="tableData" border style="width: 40%">
        <el-table-column label="序号" align="center" width="65" type="index">
       
          </el-table-column>
        <el-table-column  prop="label" label="服务" width="150">
        </el-table-column>
        <el-table-column prop="couponAdmin" label="账号/密码" >
          <template slot-scope="scope">
            {{ scope.row.accountNumber }} <el-button type="primary" icon="el-icon-document-copy" circle @click="onCopy(scope.row.accountNumber)"></el-button>
          </template>
        </el-table-column>
        <el-table-column  label="操作" >
          <template slot-scope="scope">
            <el-button @click="getCode(scope.row.value)" type="text" size="small"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <i-frame :src="url" v-if="url != null" ref="iframe" />
  </div>
</template>
  <script>
import iFrame from "@/components/iFrame/index";
export default {
  name: "Swagger",
  components: { iFrame },
  data() {
    return {
      tableData: [
        {
          value: "http://8.134.213.231:7016/doc.html",
          label: "远程服务",
          accountNumber:"couponAdmin"
        },
      ],
      queryParam: {},
      url: null,
      loading: true,
      radio: null
    };
  },
  methods: {
    getCode(value) {
      this.url = value;
    },
    // setIframeCookie() {
    //     const token = 'satoken'
    //     document.cookie = `token=${token}; domain=.your-domain.com; path=/;`
    //   }
    onCopy(text) {
      console.log(text);
      this.$copyText(text).then(
        e => {
          this.$message.success("复制成功！");
        },
        e => {
          this.$message.success("复制失败，请手动复制！");
        }
      );
    },
  },
  mounted() {
    // window.sessionStorage.getItem('token')
  },
  // mounted() {
  //   console.log('===',this.$refs.iFrame)
  //   this.$refs.iframe.addEventListener('load', () => {
  //       const iframeWindow = this.$refs.iframe.contentWindow
  //       console.log('a',window.sessionStorage.getItem('token'))
  //       iframeWindow.postMessage({ token: window.sessionStorage.getItem('token') }, '*')
  //     })
  // },
};
</script>
  